.container {
  overflow: auto;
  max-width: 100%;
  max-height: 400px;
  height: 100% !important;
  img {
    height: 100%;
    width: 100%;
    margin: 16px auto;
  }
}
.boxStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.objectives {
  max-height: 400px;
  overflow-y: auto;
}
.objectivesFullScreen {
  max-height: 650px;
  overflow-y: auto;
  @media (max-width: 1600px) {
    max-height: 400px;
  }
}

.objectives::-webkit-scrollbar,
.objectivesFullScreen::-webkit-scrollbar {
  width: 5px;
}

.objectives::-webkit-scrollbar-track,
.objectivesFullScreen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 50, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 50, 0.3);
  border-radius: 10px;
}

.objectives::-webkit-scrollbar-thumb,
.objectivesFullScreen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.quill {
  white-space: pre-wrap !important;
}
.quillFullScreen {
  white-space: pre-wrap !important;
  width: 50%;
}

.topographyStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
