.card {
  height: 220px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 1.1em;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 8px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    span {
      font-size: 13.3px;
      margin-left: -70px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    p {
      margin-top: 0.8em;
      text-align: center;
      margin-bottom: 0.5em;
    }
  }
  &__footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid black;
    &__selection {
      background: transparent;
      border: none;
      padding-bottom: 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      &:focus {
        border: none;
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__options {
    border: none;
    background-color: #c93a28;

    &:focus {
      border: none;
      outline: none;
    }
  }
  &__upload {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}

.upload {
  display: none;
}
.editIcon {
  height: 21px;
  width: 21px;
  cursor: pointer;
  margin-bottom: 4px;
}

.duration {
  width: 50px;
  text-align: right;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::placeholder {
    color: rgb(0, 0, 0);
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.pointer-cursor {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.select-container {
  & > div {
    background: transparent;
    border: none;
    color: black;
    font-size: 12px;
    width: 105%;
    text-align: center;
    outline: none !important;
    border: none !important;
    z-index: 10;
    width: 110px;
  }
  div {
    color: black;

    &:focus {
      outline: none !important;
      border: none !important;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.forbidden-cursor {
  &:hover {
    cursor: not-allowed;
  }
}
