.message {
  text-align: center;
  font-family: Arial;
  font-size: 18px;
  border: 2px dashed #f7931f;
  @media (max-height: 850px) {
    height: 80px;
    font-size: 12px;
  }

  p {
    margin: 0 auto;
  }
  padding-bottom: 10px;
  @media (max-width: 920px) {
    font-size: 16px;
  }
}

.icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 10px;
  @media (max-height: 850px) {
    height: 10px;
    width: 10px;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.upload {
  visibility: hidden;
}

.imageDiv {
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  min-height: 100px;
  max-height: 100px;
}

.imagePreview {
  width: 100px;
  object-fit: scale-down;
}

.deleteIcon {
  color: red !important;
}
