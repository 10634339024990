.frame {
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  border-radius: 4px;
}

.image {
  object-fit: scale-down;
  width: 100%;
  max-height: 100%;
  height: auto;
  border: 1px solid #eee;
  border-radius: 4px;
}
