.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 80%;
  margin: 0 auto;
  &__hero {
    height: 200px;
    width: auto;
    margin: 0 auto;

    @media screen and (max-width: 926px) {
      height: 150px;
    }
    @media screen and (max-width: 680px) {
      height: 80px;
    }
  }
}
