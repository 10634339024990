.container {
  padding: 32px;
  width: 1000px;
  &__chevronRight,
  &__chevronLeft {
    font-size: 4.5rem;
    color: orange;
  }
  @media screen and (max-width: 1000px) {
    width: 650px;
  }
  @media screen and (max-width: 660px) {
    width: 330px;
  }
}

.stepTips {
  display: flex;
  justify-content: center;
}
