.selected {
  border: 2px solid #008cba;
  display: inline-block;
  transition: 0.5s ease;
  opacity: 0.7;
  position: relative;
}

.notSelected {
  border: 2px solid white;
}
