.quote {
  font-weight: bolder !important;
  @media (max-width: 420px) {
    font-size: 2rem !important;
  }
}
.responsive {
  @media (max-width: 420px) {
    font-size: 2rem !important;
  }
}
