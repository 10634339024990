.container {
  margin: 0px auto;
  overflow: auto;
  text-align: center;
  max-height: 100%;
  &__media {
    max-width: 800px;
    max-height: 600px;
  }
  &__pdf {
    min-width: 850px;
    min-height: 600px;
  }
}

.frame {
  margin: 0px auto;
  overflow: auto;
  text-align: center;
  height: 100%;
  width: 850px;
}

.fullWindow {
  margin: 0px auto;
  overflow: auto;
  text-align: center;
  max-height: 100%;
  &__media {
    max-width: 1200px;
    max-height: 600px;
    object-fit: scale-down;
  }
  &__pdf {
    width: 1000px;
    height: 620px;
  }
}

.frameFullWindow {
  margin: 0 auto;
  overflow: auto;
  text-align: center;
  height: 100%;
  width: 1000px;
}
